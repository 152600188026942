<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="machines-page">
    <b-card class="" title="Machines in your Enterprise">
      <b-card-text>
        This page shows your MadepurpleOS in your enterprise. You can view machine statistics and monitor your machines from this page.
      </b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
<!--     <div class="row px-2 py-1">-->

<!--       &lt;!&ndash;  Switch - Show deleted  &ndash;&gt;-->
<!--       <b-col cols="12" md="3" class="py-1">-->
<!--         <b-form-checkbox name="check-button" @change="getMoreMachines(1)" v-model="filters.deleted" switch>-->
<!--           Show deleted machines-->
<!--         </b-form-checkbox>-->
<!--       </b-col>-->

<!--       &lt;!&ndash; Search &ndash;&gt;-->
<!--       <b-col cols="12" md="9" class="py-1">-->
<!--         <b-input-group>-->
<!--           <b-form-input-->
<!--             v-model="filters.search"-->
<!--             @keydown.enter="getMoreMachines(1)"-->
<!--             class="d-inline-block" placeholder="Machine ID or Name..."-->
<!--           />-->
<!--           <b-input-group-append>-->
<!--             <b-button @click="getMoreMachines(1)">Search</b-button>-->
<!--           </b-input-group-append>-->
<!--         </b-input-group>-->
<!--       </b-col>-->
<!--     </div>-->

      <b-row class="p-2">
        <b-col cols="sm-12" md="3">
          <label for="search">
            Search
          </label>
          <b-input-group>
            <b-form-input id="search" placeholder="Search by name" v-model="filters.search" @input="debouncedSearch" />
          </b-input-group>
        </b-col>

        <b-col cols="sm-12" md="3" class="mt-1 mt-md-0">
          <label for="order-by">
            Order by
          </label>
          <b-form-select id="order-by" v-model="filters.order_by" :options="orderByOptions" @change="getMoreMachines(0)"/>
        </b-col>

        <b-col cols="sm-12" md="3" class="mt-1 mt-md-0">
          <label for="order">
            Order
          </label>
          <b-form-select id="order" v-model="filters.order" :options="orderOptions" @change="getMoreMachines(0)"/>
        </b-col>
      </b-row>

      <section>
        <div class="table-responsive">
        <b-table
          class="data-table"
          hover
          :items="machines"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(updated_at)="data">
            {{data.item.updated_at | formatDateTime}}
          </template>

          <template #cell(name)="data">
            <router-link :to="{ name: 'machine-view', params: { id: data.item.id.toString() } }" class="font-weight-bold d-block text-nowrap" :class="{ 'text-secondary strike-through': data.item.deleted_at }">
              {{data.item.name}}
              <br>
              <small>{{data.item.machine_id}}</small>
            </router-link>
          </template>
          <template #cell(product_name)="data">
            {{data.item.system_vendor}} - {{data.item.product_name}}
            <br>
            <small>🔑</small> {{data.item.key_info}}
          </template>
          <template #cell(cpu_cores)="data">
            CPU {{data.item.cpu_cores}} ({{data.item.cpu_threads}}) <br>
            Daemon {{data.item.machine_stats ? data.item.machine_stats.daemon_version : "" }}
          </template>
          <template #cell(last_seen)="data">
            {{ data.item.last_seen | isOnline  }}
            {{ data.item.last_seen | formatDateTime }}
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreMachines" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
        </div>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
  import debounce from "lodash/debounce";
  import MachineService from '../../services/MachineService';

  export default {
    name: 'Machines',
    components: {},
    props: {},
    data() {
      return {
        headers: [
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'product_name',
            label: 'Product / Key in Use',
          },
          {
            key: 'cpu_cores',
            label: 'Machine',
          },
          {
            key: 'last_seen',
            label: 'Last Seen',
          },

        ],
        machines: [],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
          search: '',
          deleted: false,
          order_by: 'name',
          order: 'asc',
        },
        total_count: 0,
        debouncedSearch: debounce(this.search, 300),
        orderByOptions: [
          { value: 'name', text: 'Name' },
          { value: 'product_name', text: 'Product name' },
          { value: 'last_seen', text: 'Last seen' },
        ],
        orderOptions: [
          { value: 'asc', text: 'Ascending' },
          { value: 'desc', text: 'Descending' },
        ],
      };
    },
    mounted() {
      this.setFilters();
      this.getMachines();
    },
    methods: {
      search() {
        this.currentPage = 1
        this.getMoreMachines(this.currentPage)
      },
      getMoreMachines(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getMachines();
      },
      setFilters() {
        this.filters.deleted = this.$route.query.deleted || false
      },
      getMachines() {
        MachineService.list(this.filters).then(res => {
          this.machines = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get data, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      clearFilters() {
        this.filters = {
          page: 0,
          page_size: 25,
          search: '',
        };

        this.getMoreMachines(1);
      },
    },
  };
</script>
