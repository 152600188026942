import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/machines';

export default {
  list(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  update(id, dto) {
    return axios.put(`${baseUrl}/${id}`, dto);
  },
  delete(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
};
